import React, { useState } from 'react';
import './FormComponent.css';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    yourinterest: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send data to an API
    console.log('Form data submitted:', formData);
  };

  return (
    <form className="form-component" onSubmit={handleSubmit}>
      <div className="flex-container">
        <div className="form-group">
          <label htmlFor="name">NAME:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Your name" 
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="company">COMPANY:</label>
          <input
            type="text"
            id="company"
            name="company"
            placeholder='Your Company Name'
            value={formData.company}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="flex-container">
        <div className="form-group">
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder='Your best Email'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">PHONE:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder='Your Contact Number'
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="yourinterest">YOUR INTEREST:</label>
        <select
          id="yourinterest"
          name="yourinterest"
          value={formData.yourinterest}
          onChange={handleChange}
          required
        >
          <option value="" disabled>--Select Your Area of Interest--</option>
          <option value="webdesign">Web Design</option>
          <option value="webcare&maintenance">Web Care & Maintenance</option>
          <option value="webhosting">Web Hosting</option>
          <option value="digitalmarketing">Digital Marketing</option>
          <option value="general">General</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="message">MESSAGE:</label>
        <textarea
          id="message"
          name="message"
          placeholder='Your product details or general questions'
          value={formData.message}
          onChange={handleChange}
          required
          rows="4" 
        />
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default FormComponent;
